import config from '@/utils/ApiUtils'
import { fetchWithToken } from '../utils/Keycloak'
import downloadjs from 'downloadjs'
import moment from 'moment'

// const config().databaseBackend = 'http://0.0.0.0:9999/database/'

export default {
  get () {
    return fetchWithToken(config().databaseBackend, {
      method: 'GET'
    })
      .then((response) => response.json())
      .then((databases) => databases.sort((a, b) => (Number(a.databaseid) > Number(b.databaseid) ? -1 : 1)))
  },
  getLatest(clientId){
    return fetchWithToken(`${config().databaseBackend}latest/?clientid=${clientId}`, {
      method: 'GET'
    }).then(response => {
      return response.json()
    })
  },
  getCalendar () {
    return fetchWithToken(config().databaseBackend + 'calendar', {
      method: 'GET'
    })
      .then((response) => response.json())
    //.then((databases) => databases.sort((a, b) => (Number(a.databaseid) > Number(b.databaseid) ? -1 : 1)))
  },
  getCalendarByClient (clientid) {
    return fetchWithToken(config().databaseBackend + 'calendar?clientid=' + clientid, {
      method: 'GET'
    })
      .then((response) => response.json())
  },
  getByDateAndClient (date, clientid) {
    return fetchWithToken(config().databaseBackend + '?date=' + date + '&clientid=' + clientid, {
      method: 'GET'
    })
      .then((response) => response.json())
  },
  getByDate (date) {
    return fetchWithToken(config().databaseBackend + '?date=' + date, {
      method: 'GET'
    })
      .then((response) => response.json())
    //.then((databases) => databases.sort((a, b) => (Number(a.databaseid) > Number(b.databaseid) ? -1 : 1)))
  },
  getSource () {
    return fetchWithToken(config().databaseBackend + 'source', {
      method: 'GET'
    }).then((response) => response.json())
  },
  downloadSource (database) {
    return fetchWithToken(config().databaseBackend + 'source/' + database, {
      method: 'GET'
    })
      .then((response) => response.blob())
      .then((file) => {
        downloadjs(file, 'database.db')
      })
  },
  uploadSource (file, version) {

    const data = new FormData()
    data.append('file', file)
    data.append('version', version)

    return fetchWithToken(config().databaseBackend + 'source', {
      method: 'POST',
      body: data
    }).then((response) => response.json())
  },
  getFish (clientid) {
    return fetchWithToken(config().fishBakend + '/code/?clientid=' + clientid, {
      method: 'GET'
    }).then((response) => response.json())
  },
  updateFish (uuid, code) {
    return fetchWithToken(config().databaseBackend + 'fish/' + uuid, {
      method: 'PUT',
      body: JSON.stringify(code)
    })
  },
  deleteFish (uuid, code) {
    return fetchWithToken(config().databaseBackend + 'fish/' + uuid, {
      method: 'DELETE',
      body: JSON.stringify(code)
    })
  },
  addFish (uuid, code) {
    return fetchWithToken(config().databaseBackend + 'fish/' + uuid, {
      method: 'POST',
      body: JSON.stringify(code)
    })
  },
  getByClient (clientid) {
    return fetchWithToken(config().databaseBackend + '?clientid=' + clientid, {
      method: 'GET'
    })
      .then((response) => response.json())
      .then((databases) => {
        return databases.sort((a, b) => (Number(a.databaseid) > Number(b.databaseid) ? -1 : 1))
      } )
  },
  getOneByClient (clientid) {
    return fetchWithToken(config().databaseBackend + '?clientid=' + clientid + '&unique=true', {
      method: 'GET'
    })
      .then((response) => response.json())
  },
  download (database, name) {
    const today = moment().format('DD-MM-YYYY')
    return fetchWithToken(config().databaseBackend + 'download/' + database, {
      method: 'GET'
    })
      .then((response) => response.blob())
      .then((file) => {
        downloadjs(file, `${name}-${today}.db`)
      })
  },
  addProvide: (provider) => {
    return fetchWithToken(config().databaseBackend + 'provide', {
      body: JSON.stringify(provider),
      method: 'POST'
    })
  },
  getProvideByClient: (idClient) => {
    return fetchWithToken(config().databaseBackend + 'provide?clientId=' + idClient, {
      method: 'GET'
    }).then((response) => response.json())
  },
  getAllProvide: () => {
    return fetchWithToken(config().databaseBackend + 'provide', {
      method: 'GET'
    }).then((response) => response.json())
  },
  upload (file, mode, serial) {
    const data = new FormData()
    data.append('file', file)
    data.append('serial', serial)
    data.append('mode', mode)

    return fetchWithToken(config().databaseBackend + 'upload', {
      method: 'POST',
      body: data
    }).then((response) => response.json())
  }
}
