import socketio from 'socket.io-client'




import {ChatMessageBus} from '@/emitter/ChatMessageBus'
import ApiUtils from '@/utils/ApiUtils'


const ChatMessageService =  {
  io: socketio.connect('https://api.hublot.io', {
    path: '/chat/socket.io/',
    transports: ['websocket'],
    reconnection: true,
  }),
  updateIo() {
    ChatMessageService.io = socketio.connect(ApiUtils().backend, {
      path: '/chat/socket.io/',
      transports: ['websocket'],
      reconnection: true,
    })
  },
  joinAndListen(serial) {
    return new Promise((resolve) => {
      ChatMessageService.io.on('message_received', ChatMessageService.onMessageReceived)
      ChatMessageService.io.on('seen_received', ChatMessageService.getLastMessageSeen)
      ChatMessageService.io.emit('join_room', serial, () => {
        resolve()
      })
    })
  },
  onMessageReceived(payload) {
    ChatMessageBus.$emit('message_received', payload)
  },
  getLastMessageSeen(lastSeen_time){
    ChatMessageBus.$emit('seen_received', lastSeen_time)
  },
  getMessages(serial) {
    return new Promise((resolve, reject) => {
      ChatMessageService.io.emit(
        'get_messages',
        { room: serial },
        (error, messages = []) => {
          if(error)
            reject(error)
          else
            resolve(messages)
        }
      )
    })
  },
  getLastSeen(serial){
    return new Promise((resolve, reject) => {
      ChatMessageService.io.emit('get_last_seen',{ room: serial }, (error, lastSeen_time) => {
        if(error)
          reject(error)
        else
          resolve(lastSeen_time)
      })
    })
  },
  sendMessage(payload) {
    return new Promise((resolve, reject) => {
      ChatMessageService.io.emit('send_message', payload, error => {
        if (error) {
          reject(error)
        } else {
          resolve(payload)
        }
      })
    })
  },
  destroyListening(){
    return new Promise((resolve) => {
      ChatMessageService.io.off('message_received', ChatMessageService.onMessageReceived)
      resolve()
    })
  }
}
export default ChatMessageService
